import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user.module').then(m => m.UserPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'polls',
    loadChildren: () => import('./views/polls/polls.module').then(m => m.PollsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'posts',
    loadChildren: () => import('./views/posts/posts.module').then(m => m.PostsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'posts/:pollId',
    loadChildren: () => import('./views/posts/posts.module').then(m => m.PostsPageModule)
  },
  {
    path: 'languages',
    loadChildren: () => import('./views/languages/languages.module').then(m => m.LanguagesPageModule),
    canActivate: [LoginGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
