import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SplitPaneService {
  splitPaneState: boolean = false;

  constructor(public platform: Platform, private storage: Storage) {
    this.storage.get('logged').then(ret => {
      this.splitPaneState = ret;
    });
  }

  disableSplitPane() {
    const pageToDisableSplitPane = 'login';
    return window.location.hash.includes(pageToDisableSplitPane);
  }

  setSplitPane(state: boolean) {
    // per il mobile se mai dovesse servire
    this.splitPaneState = state && this.platform.width() > 576;
  }

  getSplitPane() {
    return this.splitPaneState;
  }
}
