import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as SockJS from 'node_modules/sockjs-client';

import { SplitPaneService } from './split-pane.service';
import { ToastController, AlertController, IonicSafeString } from '@ionic/angular';

import { Chart } from 'chart.js';

// declare function confirmJS(): void;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private socket: SockJS = null;
  public messageReceived: Subject<any>;
  public isMaster = false;

  public requestedPostsTab: string = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: Storage,
    public toastController: ToastController,
    public alertController: AlertController,
    private splitPane: SplitPaneService
  ) {
    this.messageReceived = new Subject();
    this.socket = new SockJS('/ws');
    this.socket.onmessage = (message: any) => {
      this.messageReceived.next(JSON.parse(message.data));
    };
    this.checkIfMaster();
  }

  getSummary(): Observable<any> {
    return this.http.post('/api/summary', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      }),
    );
  }

  getEventLog(logRecords: number, pageNum: number): Observable<any> {
    return this.http.post('/api/getEventLog', {
      logRecords,
      pageNum
    });
  }

  devlogin(username: string, password: string): Observable<any> {
    return this.http.post('/api/devlogin', {
      username,
      password
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Login Error', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  changePassword(userId: number, oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post('/api/changePassword', {
      userId,
      oldPassword,
      newPassword
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Login Error', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  getUsers(): Observable<any> { //USERS Page
    return this.http.post('/api/getUsers', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      }),
    )
  }

  getUser(): Observable<any> {
    return this.http.post('/api/getUser', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  getCheckUserStatus(): Observable<any> {
    return this.http.post('/api/getUser', {}).pipe(
      catchError(err => {
        this.logoff();
        return throwError(err);
      })
    );
  }

  getAgents(): Observable<any> {
    return this.http.post('/api/getAgents', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  getAnalyzers(): Observable<any> {
    return this.http.post('/api/getAnalyzers', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  saveUser(user: any): Observable<any> {
    return this.http.post('/api/saveUser', {
      user
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.post('/api/deleteUser', {
      userId
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  //admin -> tutti i polls
  //normal user -> solo i suoi
  getPolls(): Observable<any> {
    return this.http.post('/api/getPolls', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  savePoll(poll: any): Observable<any> {
    return this.http.post('/api/savePoll', {
      poll
    }) as Observable<any>;
  }

  launchPoll(pollId: number, termId: number): Observable<any> {
    const filter = [];
    if (termId > 0) {
      filter.push(termId);
    }
    return this.http.post('/api/launchPollNow', { pollId, filter }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  deletePoll(pollId: number): Observable<any> {
    return this.http.post('/api/deletePoll', {
      pollId
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  searchAuthorEntities(pollId: number, minEntities: number, minAuthors: number): Observable<any> {
    return this.http.post('/api/searchEntitiesPerAuthor', {
      pollId,
      minEntities,
      minAuthors
    }) as Observable<any>;
  }

  searchPosts(pollId: number, filter: object, currentQuery: number): Observable<any> {
    return this.http.post('/api/search', {
      pollId,
      filter,
      currentQuery
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );
  }

  searchPostAnswers(postId: number): Observable<Array<any>> {
    return this.http.post('/api/searchPostAnswers', {
      postId
    }) as Observable<Array<any>>;
  }

  searchPostsPerAuthor(authorId: number): Observable<Array<any>> {
    return this.http.post('/api/searchPostsPerAuthor', {
      authorId
    }) as Observable<Array<any>>;
  }

  searchPostsPerEntity(pollId: number, entityType: number, text: string): Observable<Array<any>> {
    return this.http.post('/api/searchPostsPerEntity', {
      pollId,
      entityType,
      text
    }) as Observable<Array<any>>;
  }

  searchPost(postId: number): Observable<any> {
    return this.http.post('/api/searchPost', {
      postId
    }) as Observable<any>;
  }

  getEntitiesData(pollId: number): Observable<any> {
    return this.http.post('/api/getEntitiesData', {
      pollId
    }) as Observable<any>;
  }

  getEntitiesMap(pollId: number): Observable<any> {
    return this.http.post('/api/getEntitiesMap', {
      pollId
    }) as Observable<any>;
  }

  getPollFilters(pollId: number): Observable<any> { //filtri relativi al poll che tu gli passi
    return this.http.post('/api/getPollFilters', {
      pollId
    }) as Observable<any>;
  }

  checkAnswer(answer: any): boolean {
    if (answer === false) {
      this.logoff();
      return true;
    }
    return false;
  }

  logoff() {
    this.storage.clear().then(ret => {
      this.http.post('/api/logout', {}).pipe().subscribe(ret => {
        this.router.navigate(['/login']);
      });
    });
  }

  async logoffConfirmation() {
    const confirm = await this.confirmationAlert(`
      <div>
        <h4>Do you really want to exit?</h4>
      </div>
    `);
    if (confirm) {
      this.storage.clear().then(ret => {
        this.http.post('/api/logout', {}).pipe().subscribe(ret => {
          this.router.navigate(['/login']);
        });
      });
    } else {
      console.log('Canceled');
    }
    return confirm;
  }

  getLanguages(): Observable<any> {
    return this.http.post('/api/getLanguages', {}).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        this.logoff();
        return throwError(err);
      })
    );;
  }

  deleteLanguage(languageId: number): Observable<boolean> {
    return this.http.post('/api/deleteLanguage', {
      languageId
    }) as Observable<boolean>;
  }

  saveLanguage(language: any, categories: any): Observable<any> {
    return this.http.post('/api/saveLanguage', {
      language,
      categories
    }) as Observable<any>;
  }

  saveLemma(lemma: any): Observable<any> {
    return this.http.post('/api/saveLemma', {
      lemma
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  getLemmaCategories(): Observable<Array<any>> {
    return this.http.post('/api/getLemmaCategories', {}) as Observable<Array<any>>;
  }

  deleteLemma(lemmaId): Observable<any> {
    return this.http.post('/api/deleteLemma', {
      lemmaId
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  searchLemmas(searchKey: string, categoryId: number, languageId: any): Observable<any> {
    return this.http.post('/api/searchLemmas', {
      searchKey,
      categoryId,
      languageId
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  downloadLemmas(language: any) {
    window.location.href = '/api/getAllLemmas?languageId=' + language.id;
  }

  downloadSentences(language: any) {
    window.location.href = '/api/getAllSentences?languageId=' + language.id;
  }

  uploadLemmas(language: any, fileToUpload: File, append: any): Observable<boolean> {
    const endpoint = '/api/uploadLemmas';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('languageId', language.id);
    formData.append('append', append);
    return this.http.post(endpoint, formData) as Observable<boolean>;
  }

  uploadSentences(language: any, fileToUpload: File, append: any): Observable<boolean> {
    const endpoint = '/api/uploadSentences';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('languageId', language.id);
    formData.append('append', append);
    return this.http.post(endpoint, formData) as Observable<boolean>;
  }

  setScore(sentence: any, score: number): Observable<any> {
    return this.http.post('/api/setScore', {
      sentenceId: sentence.id,
      score
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  saveSentence(sentence: any): Observable<any> {
    return this.http.post('/api/saveSentence', {
      sentence
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  deleteSentence(sentenceId: number): Observable<any> {
    return this.http.post('/api/deleteSentence', {
      sentenceId
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  searchSentences(searchKey: string, language: any): Observable<any> {
    return this.http.post('/api/searchSentences', {
      searchKey,
      languageId: language.id
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );
  }

  searchUnscoredSentences(language: any): Observable<Array<any>> {
    return this.http.post('/api/searchUnscoredSentences', {
      languageId: language.id
    }) as Observable<Array<any>>;
  }

  startTraining(language: any): Observable<any> {
    return this.http.post('/api/startTraining', {
      languageId: language.id
    }).pipe(
      catchError(err => {
        console.log('Caught mapping error and rethrowing', err);
        this.presentToast('Internal Server Error - Exiting application...', 'danger');
        // this.logoff();
        return throwError(err);
      })
    );;
  }

  getPivotTable(pollId: number, timeLevel: number): Observable<any> {
    return this.http.post('/api/getPivotTable', {
      pollId,
      timeLevel
    }) as Observable<any>;
  }

  getToolsPerUser(userId: number): Observable<any> {
    return this.http.post('/api/getToolsPerUser', {
      userId
    }) as Observable<any>;
  }


  // CUSTOM FUNCTIONS
  async presentToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  async confirmationAlert(message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    const alert = await this.alertController.create({
      message: new IonicSafeString(message),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'button-danger-alert',
          handler: () => resolveFunction(false)
        },
        {
          text: 'Confirm',
          cssClass: 'button-success-alert',
          handler: () => resolveFunction(true)
        }
      ]
    });
    await alert.present();
    return promise;
  }

  checkIfMaster() {
    this.storage.get('user').then(u => {
      this.isMaster = u ? u.master : false;
    }).catch(err => {
      this.presentToast(err, 'danger');
      this.logoff();
    })
  }

  showPage(page) {
    const find = page.showIf.find(p => p === (this.isMaster ? 'master' : 'user'));
    return Boolean(find);
  }

  async getUserInfo() {
    return await this.storage.get('user');
  }

  cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  // Chart Functions
  drawPieChart(canvasId, data) {
    var el: any = document.getElementById(canvasId);
    if (!el) return;
    var ctx = el.getContext('2d');
    var labels = [];
    var values = [];
    var colors = [];
    data.forEach(element => {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}a6`;
      labels.push(element[0]);
      values.push(element[1]);
      colors.push(color);
    });
    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: colors
        }]
      },
      options: {
        segmentShowStroke: false,
        plugins: {
          colorschemes: {
            scheme: 'tableau.Tableau20'
          }
        }
      }
    });
  };

  drawLineChart(canvasId, labels, datasets) {
    var el: any = document.getElementById(canvasId);
    if (!el) return;
    var ctx = el.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        segmentShowStroke: false,
        plugins: {
          colorschemes: {
            scheme: 'tableau.Tableau20'
          }
        },
        maintainAspectRatio: false,
        responsive: true
      }
    });
  };
}

